<template>
    <!-- <div class="inner_banner"><a class="db" href="#"><img :src="require('@/assets/images/ztb_banner.png')"/></a></div> -->
    <main class="ztb_main">
      <div class="ztb_box">
        <ul>
          <li>{{biddingDetail.title}}</li>
          <li>
            <div class="item_box"><span>项目类型</span><span>{{biddingDetail.projectType}}</span></div>
          </li>
          <li v-show="memberLevel>1">
            <div class="item_box"><span>发布时间</span><span>{{biddingDetail.startTime}}</span></div>
          </li>
          <li>
            <div class="item_box"><span>行政区域</span><span>{{biddingDetail.areas}}</span></div>
          </li>
          <li>
            <div class="item_box"><span>医院名称</span><span>{{biddingDetail.hospitalName}}</span></div>
          </li>
          <li>
            <div class="item_box"><span>医院等级</span><span>{{biddingDetail.level}}</span></div>
          </li>
          <li>
            <div class="item_box"><span>{{biddingDetail.projectType==="招标"?"采购预算":"中标金额"}}</span><span>{{biddingDetail.price}}</span></div>
          </li>
          <li>
            <div class="item_box"><span>中标供应商</span><span>{{biddingDetail.vendorsName}}</span></div>
          </li>
          <li>
            <div class="item_box"><span>中标项目类型</span><span>{{biddingDetail.theBiddingTypeName}}</span></div>
          </li>
          <li v-show="memberLevel>1">
            <div class="item_box"><span>项目范围</span><span class="proj_scope">{{biddingDetail.projectScope}}</span></div>
          </li>
          <li v-show="memberLevel>1">
            <div class="item_box"><span>{{biddingDetail.projectType==="招标"?"开标时间":"中标时间"}}</span><span>{{biddingDetail.endTime}}</span></div>
          </li>
          <li v-show="memberLevel>1">
            <div class="item_box"><span>特殊公告类型</span><span>{{biddingDetail.noticeType}}</span></div>
          </li>
          <li v-show="memberLevel>1">
            <div class="item_box"><span>公告地址</span><span><a :href="biddingDetail.noticeAddress" target="_blank" class="bidding_url cup">点击查看详情</a></span></div>
          </li>
        </ul>
        <div v-show="memberLevel>1" class="details_box">
          <p v-html="biddingDetail.noticeContent"></p>
        </div>

        <div class="btn_box w100p cf mt20">
          <el-button type="primary" @click="exportExcel(biddingDetail.title)"
          >下载<i class="el-icon-download el-icon--right"></i
          ></el-button>
        </div>

      </div>
      <div class="ztb_latest">
        <div class="title">最新招投标信息</div>
        <ul>
          <li v-for="item in topList" :key="item.id" class="top-item" @click="showDetail(store,router,'bidding','BiddingDetail',item,getBiddingDetail)"><a herf="javascript:void(0);">{{item.title}}</a></li>
        </ul>
      </div>
    </main>
</template>
<script setup>
import {useRouter} from "vue-router"
import {computed} from "vue"
import {useStore} from "vuex"
import useBiddingDetail from "@/composable/bidding/useBiddingDetail"


const store=useStore()
const router=useRouter()
const {getBiddingDetail,biddingDetail,topList,exportExcel}=useBiddingDetail()
  const memberLevel=computed(()=>store.state.userState.level)
</script>
<style lang="less" scoped>
    .top-item:hover,.top-item>a:hover{
        cursor: pointer;
        color: #409eff;
    }
  
    .ztb_box{
      ul{
        background:#fff;
        box-shadow: 0px 1px 100px 0px #ccc;
        margin-bottom: 20px;
      }
      li:first-child{
        height: 60px;
        line-height: 60px;
        text-align: center;
        font-size:18px;
        color:#fff;
        background:#1188f7;
      }
    }

    .ztb_main .ztb_box .item_box{
      height: auto;
    }

    .proj_scope{
      width:500px;
      line-height: 1.5;
      vertical-align: middle;
    }

    .ztb_box li:first-child{
      height: auto;
      line-height: 1.5;

      box-sizing: border-box;
      padding:10px 15px;
    }


    // 2021 10 22
    .bidding_url{color:#1188f7;cursor: pointer;;}
</style>
import {onMounted,reactive,ref} from "vue"
import {useRoute,useRouter} from "vue-router"
import $api from "@/api"
// 2021 10 26
import downloadFile from "@/utils/file/downLoadFile"
import {getImgUrl} from "@/utils/imageRelated"
import dialog from "@/utils/ui/dialog"
// end 2021 10 26

export default function useBiddingDetail(){
    const router=useRouter()
    const route=useRoute()
    const {id}=route.query
    
    const biddingDetail=reactive({})
    const getBiddingDetail=async(id)=>{
        const [err,data]=await $api.bidding.getBiddingDetail(id)
        if(!data.succeeded) {
            Dialog.alert("抱歉",data.errors,true,false,()=>{
                router.push({
                    name:"Bidding"
                })
            })
        };
        Object.assign(biddingDetail,data.data)
    }
    
    const topList=ref([])
    const getBiddingTop=async()=>{
        const [err,data]=await $api.article.getBidTop5({Number:5})
        if(!data.succeeded) return;
        topList.value=data.data
    }

    // 2021 10 25 导出
    const exportExcel=async(title)=>{
        const [err,data]=await $api.bidding.getBiddingDetailExport(id)
        downloadFile(getImgUrl(data.data),title)
    }
    // end- 2021 10 25


    onMounted(()=>{
        getBiddingDetail(id)
        getBiddingTop()
    })
    return {
        getBiddingDetail,
        biddingDetail,
        topList,
        exportExcel
    }
}